import prestashop from 'prestashop';

export let productDjv = {
    version: "v1.7.6.5 build:djv20210505.1630",
    log: (...argsArray) => {
        let args = Array.from(argsArray);
        console.log.apply(console, args);
    },

    init: () => {
        productDjv.log("productDjv.init");
        productDjv.log(productDjv.version);
        //productDjv.log(["prestashop", prestashop]);

        prestashop.on("eventoInventado", (event) => {
            productDjv.log("eventoInventado");
        });
    },

    updateProductSuccess: (replaceAddToCartSections,  eventType, $quantityWantedInput, data, textStatus, errorThrown) => {
        productDjv.log("updateProduct success");
        // Avoid image to blink each time we modify the product quantity
        // Can not compare directly cause of HTML comments in data.
        const $newImagesContainer = $('<div>').append(data.product_cover_thumbnails);

        // Used to avoid image blinking if same image = epileptic friendly
        if ($('.images-container').html() !== $newImagesContainer.find('.images-container').html()) {
          $('.images-container').replaceWith(data.product_cover_thumbnails);

          $(".thumbnails-item img").hover(function() {
            var newSrc = $(this)
              .attr("src")
              .replace("small_default", "medium_default");
            $(".product-cover img").attr({"src": newSrc});
          });
        }

        $('.quickview .product-prices, .page-product:not(.modal-open) .row .product-prices').replaceWith(data.product_prices);
        $('.quickview .product-customization, .page-product:not(.modal-open) .row .product-customization').replaceWith(data.product_customization);

        // TODO: utilizar la variable djv_flags
        $("*[itemprop=\"sku\"]").text(data.combination_reference);
        $('.quickview .product-discounts, .page-product:not(.modal-open) .row .product-discounts').replaceWith(data.product_discounts);

        //Calzados es un caso especial que no actualiza al cambiar de talla o color, lo hace todo mediante javascript
        if (djv_project == "calzadoscomodos") {

        } else {
          $('.quickview .product-variants, .page-product:not(.modal-open) .row .product-variants').replaceWith(data.product_variants);
          $('.quickview .product-additional-info, .page-product:not(.modal-open) .row .product-additional-info').replaceWith(data.product_additional_info);
          $('.quickview #product-details, #product-details').replaceWith(data.product_details);         $('.quickview .product-flags, .page-product:not(.modal-open) .row .product-flags').replaceWith(data.product_flags)
        }

        replaceAddToCartSections(data);
        const minimalProductQuantity = parseInt(data.product_minimal_quantity, 10);

        // Prevent quantity input from blinking with classic theme.
        if (!isNaN(minimalProductQuantity)
            && eventType !== 'updatedProductQuantity'
        ) {
          $quantityWantedInput.attr('min', minimalProductQuantity);

          if ($quantityWantedInput.val() < minimalProductQuantity) {
            $quantityWantedInput.val(minimalProductQuantity);
          }
        }
        prestashop.emit('updatedProduct', data);
      },
};
